import React from 'react';
import firebase from 'firebase';
import { Snackbar } from '@mui/material';

const firebaseConfig = {
	apiKey: 'AIzaSyDoV-_72BUzRbzuwKCgsuVudYjiZws8u1w',
	authDomain: 'arrived-backend.firebaseapp.com',
	projectId: 'arrived-backend',
	storageBucket: 'arrived-backend.appspot.com',
	messagingSenderId: '566769008714',
	appId: '1:566769008714:web:65da3b9640a3194b6fe6b3',
	measurementId: 'G-DF498KC5SC',
};

export const FirebaseContext = React.createContext(null);

export const FirebaseProvider = ({ children }) => {
	const [fb, setFB] = React.useState(null);
	const [db, setDB] = React.useState(null);
	const [errorMessage, setErrorMessage] = React.useState(null);
	const [openError, setOpenError] = React.useState(true);

	React.useEffect(() => {
		let fireAPP;
		if (!firebase.apps.length) {
			fireAPP = firebase.initializeApp(firebaseConfig);
		} else {
			fireAPP = firebase.app();
		}

		const fireDB = fireAPP.firestore();

		setFB(fireAPP);
		setDB(fireDB);
	}, []);

	React.useEffect(() => {
		setOpenError((prev) => !prev);
	}, [errorMessage]);

	const handleClose = () => {
		setErrorMessage(null);
	};

	return (
		<FirebaseContext.Provider value={{ fb, db, setErrorMessage }}>
			<Snackbar
				anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
				open={openError}
				onClose={handleClose}
				message={errorMessage}
				autoHideDuration={2000}
			/>
			{children}
		</FirebaseContext.Provider>
	);
};
